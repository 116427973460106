import logo from './rsc-logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h2>Building Services</h2>
        <ul>
          <li><span>■</span> Carpentry</li>
          <li><span>■</span> Joinery</li>
          <li><span>■</span> Plumbing</li>
          <li><span>■</span> Electrics</li>
          <li><span>■</span> Renovations</li>
          <li><span>■</span> Extensions</li>
        </ul>
        <a class="contact-link" href="mailto:dan@rscbuildingservices.co.uk">Contact us</a>
      </header>
    </div>
  );
}

export default App;
